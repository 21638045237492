import React, { useEffect, useRef } from 'react'
import DateSelectionTrigger from './DateSelectionTriger'
import { DateRangePicker } from 'react-dates'
import { useSelector } from 'react-redux'
import { getLabels } from '../../redux/reducers/configuration'
import moment from 'moment'

function getCurrentProperty(propertyCode) {
  const storageData = JSON.parse(localStorage.getItem('vitQueryCardParams'))
  return storageData?.properties?.length
    ? storageData.properties.find(
        property => property.propertyCode === propertyCode
      )
    : null
}

export function checkDateIsBlocked(day, propertyCode) {
  const currentProperty = getCurrentProperty(propertyCode)
  const propertyCloseDate = currentProperty?.propertyCloseDate
  const propertyOpenDate = currentProperty?.propertyOpenDate

  if (propertyCloseDate && propertyOpenDate) {
    return Boolean(
      day.isBetween(
        moment(propertyCloseDate, 'YYYY-MM-DD'),
        moment(propertyOpenDate, 'YYYY-MM-DD')
      ) || day.isBefore(moment(), 'days')
    )
  }

  return day.isBefore(moment(), 'days')
}

function exceededMaxNights({ day, selectedStartDate, maxNights }) {
  return Boolean(
    maxNights &&
      selectedStartDate &&
      day.isAfter(selectedStartDate.clone().add(+maxNights, 'days'), 'days')
  )
}

const Dates = ({
  selectedStartDate,
  handleOpenDatePicker,
  selectedEndDate,
  setSelectedStartDate,
  focusedInput,
  setSelectedEndDate,
  setFocusedInput,
  isVisible,
  minNights,
  maxNights,
  setDatepickerIsVisible,
  propertyCode,
  isSingleDate = false
}) => {
  const labels = useSelector(getLabels)

  const calendarContainerRef = useRef(null)

  useEffect(() => {
    // clicking outside of the calendar should close the calendar, but not the widget
    // we'll try to intercept the CMS's outside click handler when our calendar is open
    function handleOutSideClick(e) {
      if (
        isVisible &&
        (!calendarContainerRef.current ||
          !calendarContainerRef.current.contains(e.target))
      ) {
        const cmsTrigger = document.querySelector('.js-booking-open')
        setDatepickerIsVisible(false)

        const allowClose =
          cmsTrigger &&
          (e.target === cmsTrigger || cmsTrigger.contains(e.target))

        if (!allowClose) {
          e.stopPropagation()
        }
      }
    }

    document.body.addEventListener('click', handleOutSideClick)

    return () => {
      document.body.removeEventListener('click', handleOutSideClick)
    }
  }, [isVisible, setDatepickerIsVisible])

  const initMonth = (propertyCode, selectedStartDate, propertyOpenDate) => {
    if (propertyCode === '') {
      return moment(selectedStartDate, 'YYYY-MM-DD')
    } else if (checkDateIsBlocked(moment(), propertyCode) && propertyOpenDate) {
      if (selectedStartDate === null) {
        return moment(propertyOpenDate, 'YYYY-MM-DD')
      }
      return moment(selectedStartDate, 'YYYY-MM-DD')
    } else if (selectedStartDate !== null) {
      return moment(selectedStartDate, 'YYYY-MM-DD')
    }
    return moment()
  }

  const currentProperty = getCurrentProperty(propertyCode)
  const propertyOpenDate = currentProperty?.propertyOpenDate
  const initialMonth = initMonth(
    propertyCode,
    selectedStartDate,
    propertyOpenDate
  )

  return (
    <div className="date-selection">
      <DateSelectionTrigger
        date={selectedStartDate}
        handleOpenDatePicker={() => handleOpenDatePicker('startDate')}
        label={isSingleDate ? 'Date' : labels.arrivalDate}
      />
      <div
        className={`calendar-container ${isVisible ? 'visible' : 'hidden'}`}
        ref={calendarContainerRef}
      >
        <DateRangePicker
          startDate={selectedStartDate} // momentPropTypes.momentObj or null,
          startDateId="calendar-card-arrival"
          endDate={isSingleDate ? selectedStartDate : selectedEndDate} // momentPropTypes.momentObj or null,
          endDateId="calendar-card-departure"
          onDatesChange={({ startDate, endDate }) => {
            setSelectedStartDate(
              startDate,
              isSingleDate ? startDate.clone().add(2, 'days') : null
            )
            if (focusedInput === 'startDate') {
              if (isSingleDate) {
                return setDatepickerIsVisible(false)
              } else {
                setFocusedInput('endDate')
              }
            } else {
              // check that every day in between is available??
              if (startDate && endDate) {
                const totalDays = endDate.diff(startDate, 'days')
                let hasBlockedDay = false

                for (let i = 1; i < totalDays; i++) {
                  if (
                    checkDateIsBlocked(
                      startDate.clone().add(i, 'days'),
                      propertyCode
                    )
                  ) {
                    hasBlockedDay = true
                    break
                  }
                }

                if (!hasBlockedDay) {
                  setSelectedEndDate(endDate)
                  setDatepickerIsVisible(false)
                }
              }
            }
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          initialVisibleMonth={() =>
            initialMonth.isAfter(moment(), 'days') ? initialMonth : moment()
          } // PropTypes.func or null,
          minimumNights={Number(minNights)}
          numberOfMonths={1}
          daySize={44}
          isDayBlocked={day =>
            checkDateIsBlocked(day, propertyCode) ||
            (focusedInput === 'endDate' &&
              exceededMaxNights({ day, selectedStartDate, maxNights }))
          }
          keepOpenOnDateSelect={true}
          onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
          transitionDuration={0}
        />
      </div>
      {isSingleDate ? null : (
        <DateSelectionTrigger
          date={selectedEndDate}
          handleOpenDatePicker={() => handleOpenDatePicker('endDate')}
          label={labels.departureDate}
        />
      )}
    </div>
  )
}

export default Dates
