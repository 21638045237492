import React from 'react'
import { useSelector } from 'react-redux'
import { getLabels } from '../../redux/reducers/configuration'
import _ from 'lodash'

const Properties = ({
  handleUpdateProperty,
  properties,
  propertyValue,
}) => {
  const labels = useSelector(getLabels)

  function renderOptions() {
    let options = []

    for (const propertyKey in properties) {
      const property = properties[propertyKey]

      if (!_.isEmpty(property.propertyCode) && !_.isEmpty(property.name) && property.propertyEnabled) {
        options.push(
          <option
            key={`property-${propertyKey}`}
            value={property.propertyCode}
          >
            {property.name}
          </option>
        )
      }
    }

    return options
  }

  return (
    <div className="property-container">
      <div className="u-flex property-selectors">
        <div className="property-selector">
          <label htmlFor="field-properties" className="label u-remove-margin">
            {labels.property}
          </label>

          <select
            id="field-properties"
            className="u-full-width"
            value={propertyValue}
            onChange={e => handleUpdateProperty(e.target.value)}
          >
            <option value="">{labels.allProperties}</option>
            {renderOptions()}
          </select>
        </div>
      </div>
    </div>
  )
}

export default Properties
