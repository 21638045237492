import React from 'react'
import calIcon from '../../assets/icons/calendar.svg'

export const triggerDateFormats = {
  weekday: 'dddd',
  mainDisplay: 'MMMM D'
}

const DateSelectionTrigger = ({ date, handleOpenDatePicker, label }) => {
  return (
    <div className="date-selection-container">
      <p className="label">{label}</p>
      <button
        className={`btn date-selection-button  ${date ? 'has-value' : ''}`}
        onClick={handleOpenDatePicker}
      >
        <div className="u-flex button-content">
          {date ? (
            <div>
              <p className="u-remove-margin weekday">
                {date.format(triggerDateFormats.weekday)}
              </p>
              <p className="u-remove-margin date">
                {date.format(triggerDateFormats.mainDisplay)}
              </p>
            </div>
          ) : (
            <p className="u-remove-margin placeholder">{label}</p>
          )}
          <img src={calIcon} alt={label} />
        </div>
      </button>
    </div>
  )
}

export default DateSelectionTrigger
