import React from 'react'
import '../../styles/scss/elements/error-boundary.scss'

const ErrorBoundary = props => {
  const {
    containerClasses,
    errorMessage,
    errorTitle,
    responseError,
  } = props

  return (
    <div className={ containerClasses ? containerClasses : 'u-error-boundary' }>
      { errorTitle && <h4>{errorTitle}</h4> }

      <p className="u-remove-margin">
        { errorMessage ? errorMessage : 'There was an issue retrieving configuration - please wait a moment, then try again.' }
      </p>

      {responseError && <p className="u-element-hidden">Error Message: { responseError }</p>}
    </div>
  )
}

export default ErrorBoundary
