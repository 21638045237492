import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import 'react-dates/initialize'

import { configurationData } from '../../redux/reducers/configuration'
import { fetchConfiguration } from '../../redux/actions/fetchConfiguration'
import { packageData } from '../../redux/reducers/packages'
import { fetchPackages } from '../../redux/actions/fetchPackages'

import ErrorBoundary from '../../components/elements/ErrorBoundary'
import BrandLoader from '../../components/elements/BrandLoader'
import ReservationCard from '../../components/ReservationCard'

function App() {
  const initialOptions = JSON.parse(localStorage.getItem('vitQueryCardParams'))
  const clientId =
    initialOptions?.widgetType === 'vit-trade'
      ? process.env.REACT_APP_TRADE_CLIENT_ID
      : process.env.REACT_APP_FLOW_CLIENT_ID

  const configuration = useSelector(state => configurationData(state)),
    packages = useSelector(packageData),
    dispatch = useDispatch()

  useEffect(() => {
    // doing it this way rather then only on load to prevent having to wait when using hot reload.
    if (!configuration.data && !configuration.loading && !configuration.error) {
      dispatch(fetchConfiguration(clientId))
    }
  }, [dispatch, configuration, clientId])

  useEffect(() => {
    if (!packages.data && !packages.loading && !packages.error) {
      dispatch(fetchPackages(clientId))
    }
  }, [dispatch, packages, clientId])

  return (
    <div
      className="vit__query-reservation-card"
      data-testid="vit__query-reservation-card"
    >
      {configuration.loading ? (
        <BrandLoader />
      ) : configuration.error ? (
        <ErrorBoundary errorMessage={configuration.error} />
      ) : (
        <ReservationCard />
      )}
    </div>
  )
}

export default App
