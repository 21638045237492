export default {
  hotel: {
    adult: 'Adult',
    adults: 'Adults',
    allProperties: 'All Hotels',
    arrivalDate: 'Arrival Date',
    assistanceNeededCopy: 'Need assistance? Call:',
    child: 'Child',
    children: 'Children',
    checkRates: 'Check Rates',
    close: 'close',
    departureDate: 'Departure Date',
    guests: 'Guests',
    promoCode: 'Promo Code',
    property: 'Hotel',
    rateGroupCodeLabel: 'Group ID / Rate Code',
    remove: 'remove',
    room: 'Room',
    rooms: 'Rooms',
    roomsAdd: 'Add a room',
    specialCodeMessage: 'I have a promo code',
    specialRates: 'Special Rates'
  }
}
