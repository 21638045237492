import React from 'react'
import brandLoader from '../../assets/images/brand-loader.gif'

import '../../styles/scss/elements/brand-loader.scss'

const BrandLoader = props => {
  const {
    containerClasses,
    loaderSrc,
  } = props

  return (
    <div className={ containerClasses ? containerClasses : 'u-brand-loader' }>
      <img
        src={ loaderSrc ? loaderSrc : brandLoader }
        alt="Brand Loading Animation"
      />
    </div>
  )
}

export default BrandLoader
