import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'core-js'
import 'airbnb-browser-shims'

import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'

import App from './containers/structure/App'
import configureStore from './redux/store'

import './styles/scss/base/base.scss'
import './styles/scss/base/buttons.scss'
import './styles/scss/base/forms.scss'
import './styles/scss/base/modal.scss'
import './styles/scss/base/print.scss'
import './styles/scss/base/typography.scss'
import './styles/scss/base/utilities.scss'
import './styles/scss/base/datepicker.scss'
const store = configureStore()

const renderApp = () =>
  render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('vit__query-reservation-card')
  )

if (process.env.NODE_ENV !== 'production' && module.hot) {
  module.hot.accept('./containers/structure/App', renderApp)
}

renderApp()
