import queryString from 'query-string'
import { merge, get } from 'lodash'
import { configuration } from '../../api/api'
import labelsConfig from '../../configuration/labels'

export const FETCH_CONFIG_BEGIN = 'FETCH_CONFIG_BEGIN'
export const FETCH_CONFIG_SUCCESS = 'FETCH_CONFIG_SUCCESS'
export const FETCH_CONFIG_ERROR = 'FETCH_CONFIG_ERROR'

export const fetchConfiguration = clientId => async dispatch => {
  dispatch({ type: FETCH_CONFIG_BEGIN })
  const queryParams = queryString.parse(window.location.search)

  let language = 'en'

  if (queryParams.language) {
    language = queryParams.language
  }

  return configuration(language, clientId)
    .then(res => {
      if (res.data && res.data['hydra:member']) {
        const payload = reduceConfig(res)
        dispatch({
          type: FETCH_CONFIG_SUCCESS,
          payload
        })
      } else {
        dispatch({
          type: FETCH_CONFIG_ERROR,
          payload:
            'There was an error retrieving configuration - please wait a moment, then try again.'
        })
      }
    })
    .catch(err => {
      dispatch({ type: FETCH_CONFIG_ERROR, payload: err })
    })
}

function reduceConfig(configResponse) {
  const data = get(configResponse, "data.['hydra:member']")
  if (data) {
    return data.reduce((obj, config) => {
      const { id, options, labels } = config

      obj[id] = {
        options,
        labels: merge({}, labelsConfig[id], labels ? labels[0] : {})
      }

      return obj
    }, {})
  }
}
