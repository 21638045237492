import axios from 'axios'

const endpoints = {
  configuration: process.env.REACT_APP_ENDPOINT_DOLLI + '/config',
  packages: process.env.REACT_APP_ENDPOINT_DOLLI + '/content/packages'
}

export const configuration = (lang, clientId) => {
  return axios.get(
    `${
      endpoints.configuration
    }?clientId=${clientId}&primaryLangId=${lang.toString()}`
  )
}

export const packages = clientId => {
  return axios.get(`${endpoints.packages}?clientId=${clientId}`)
}

export default endpoints
