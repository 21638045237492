import { combineReducers } from 'redux'
import configurationData from './configuration'
import packageData from './packages'

const rootReducer = combineReducers({
  configurationData,
  packageData
})

export default rootReducer
