import { get } from 'lodash'
import {
  FETCH_CONFIG_BEGIN,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_ERROR
} from '../actions/fetchConfiguration'
import labels from '../../configuration/labels'

const initialState = {
  loading: false,
  error: null,
  data: null
}

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONFIG_BEGIN:
      return Object.assign({}, state, {
        loading: true
      })
    case FETCH_CONFIG_SUCCESS:
      return Object.assign({}, state, {
        loading: false,
        data: action.payload
      })
    case FETCH_CONFIG_ERROR:
      return Object.assign({}, state, {
        loading: false,
        error: action.payload
      })
    default:
      return state
  }
}

export const configurationData = state => state.configurationData

export const getLabels = (state, parent = 'hotel') => {
  const allLabels = get(
    state,
    ['configurationData', 'data', 'config', parent, 'labels'],
    null
  )
  return allLabels ? allLabels : labels[parent] ? labels[parent] : {}
}
