import React from 'react'
import PropTypes from 'prop-types'

const TextInput = ({
  error,
  label,
  name,
  onChange,
  placeholder,
  type,
  value
}) => {
  return (
    <>
      <label className="u-margin-y-1 u-font-heading u-bold u-font-sm">
        <p className="label u-remove-margin">{label}</p>
        <input
          name={name}
          type={type}
          onChange={onChange}
          value={value}
          placeholder={placeholder}
        />
      </label>
      {error && <span className="u-font-xs u-color-error">{error}</span>}
    </>
  )
}

TextInput.defaultProps = {
  error: null,
  type: 'text',
  name: '',
  placeholder: ''
}

TextInput.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired
}

export default TextInput
