import React, { useRef, useState } from 'react'
import AnimateHeight from 'react-animate-height'
import TextInput from '../forms/TextInput'

import removeIcon from '../../assets/icons/remove-promo-code.svg'
import '../../styles/scss/elements/special-codes.scss'

const SpecialCodes = ({displayPromoField, handleExpandEvent, isExpanded, labels, validCodes, promoCodes, setPromoCodes}) => {
  const [promoCode, setPromoCode] = useState('')
  const [invalidCode, setInvalidCode] = useState(false)

  const specialCodesMenuRef = useRef(null)

  const handleApplyButtonClick = () => {
    setInvalidCode(false)
    if (validCodes.indexOf(promoCode) !== -1) {
      if (promoCodes.indexOf(promoCode) === -1) {
        setPromoCodes([...promoCodes, promoCode])
      }
    } else {
      setInvalidCode(true)
    }
    setPromoCode('')
  }

  const handleRemoveCode = code => {
    setPromoCodes(promoCodes.filter(check => check !== code))
    specialCodesMenuRef.current.focus()
  }

  return (
    <div className="special-codes-toggle">
      <button
        className="btn btn-text-only u-font-sm u-text-center u-full-width"
        onClick={() => handleExpandEvent(!isExpanded)}
      >
        {labels.specialCodeMessage} {isExpanded ? '-' : '+'}
      </button>
      <AnimateHeight duration={250} height={isExpanded ? 'auto' : 0}>
        <div className="special-codes-menu" ref={specialCodesMenuRef}>
          {displayPromoField &&
              <div className={`u-full-width u-flex ${promoCodes[0] && 'special-codes-max'}`} >
              <TextInput
                label=""
                onChange={(e) => setPromoCode(e.target.value.toUpperCase())}
                value={promoCode}
                placeholder="Promo Code"
              />
              <div className="u-flex">
                <button className="btn" onClick={handleApplyButtonClick}>
                  Apply
                </button>
              </div>
            </div>
          }

          {invalidCode && <div className="u-color-error">✗ Invalid Promo</div>}
            <div>
              {promoCodes.map(code => (
                <div className="u-flex u-flex-space-between" key={code}>
                  <div>✓ Promo Applied</div>
                  <div className="special-codes-code">{code}</div>
                  <button
                    className="special-codes-remove-code"
                    onClick={() => handleRemoveCode(code)}
                    aria-label="Remove promo code"
                  >
                    <img src={removeIcon} alt="Remove promo code" />
                  </button>
                </div>
              ))}
            </div>
        </div>
      </AnimateHeight>
    </div>
  )
}

export default SpecialCodes
